export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyCWQ4AQ0EyWubVPlNuw0P72euuenGejdwY",
    authDomain: "cero-4dee0.firebaseapp.com",
    databaseURL: "https://cero-4dee0.firebaseio.com",
    projectId: "cero-4dee0",
    storageBucket: "cero-4dee0.appspot.com",
    messagingSenderId: "626896240763",
    appId: "1:626896240763:web:e2bf9c21ba0aaaae3422de",
    measurementId: "G-TFL1FMZ6H5"
  }
};
