<div class="container">
  <form novalidate (ngSubmit)="save()" [formGroup]="jobForm">
      <div class="form-group row mb-2">
          <label for="jobTitleId" class="col-md-2  col-form-lable">
              Job Title
          </label>
          <div class="col-md-8">
              <input class="form-control"
                      id="jobTitleId"
                      type="text"
                      required
                      minlength="3"
                      placeholder="Job Title"
                      formControlName="jobTitle">
          </div>
      </div>
      <div class="form-group row mb-2">
          <label for="jobExperienceId" class="col-md-2  col-form-lable">
              Job Experience
          </label>
          <div class="col-md-8">
              <input class="form-control"
                      id="jobExperienceId"
                      type="text"
                      required
                      minlength="2"
                      placeholder="Job Experience"
                      formControlName="experience">
          </div>
      </div>
      <div class="form-group row mb-2">
          <label for="jobExperienceId" class="col-md-2  col-form-lable">
              Active Posting?
          </label>
        <div class="col-md-8">
          <div class="form-check">
              <input class="form-check-input"
                     id="active"
                     type="checkbox"
                     formControlName="active">
          </div>
        </div>
      </div>
      <div class="form-group row mb-2">
          <div class="formGroup">
              <a class="btn btn-primary" (click)="addSkills()">
                <i class="fab fa-plus"></i>Add Skills
              </a>
          </div>
      </div>
      <div class="form-group row mb-2">
        <label for="jobExperienceId" class="col-md-2  col-form-lable">
            <!-- Skills? -->
        </label>
        <div class="col-md-8">
          <div class="row form-group" *ngFor="let control of skills.controls; let i=index">
            <div class="col-md-8">
              <input class="form-control" [formControl]="skills.controls[i]" />
            </div>
            <div class="col-md-4">
              <button class="btn btn-danger"  (click)="removeSkill(i)">X</button>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row mb-2">
        <div class="formGroup">
            <a class="btn btn-primary" (click)="addResposibilities()">
              <i class="fab fa-plus"></i>  Add Responsibilities
            </a>
        </div>
    </div>
      <div class="form-group row mb-2">
        <label for="jobExperienceId" class="col-md-2  col-form-lable">
          <!-- Responsibilities? -->
        </label>
        <div class="col-md-8">
          <div class="row form-group" *ngFor="let control of resposibilities.controls; let i=index">
            <div class="col-md-8">
              <input class="form-control" [formControl]="resposibilities.controls[i]" />
            </div>
            <div class="col-md-4">
              <button class="btn btn-danger"  (click)="removeResponsibilities(i)">X</button>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row mb-2">
          <div class="offset-md-2 col-md-4">
              <button class="btn btn-primary mr-3"
              type="submit"
              style="width: 80px;">Save</button>
          </div>
          <div class="offset-md-2 col-md-4 pull-right">
              <a class="btn btn-secondary" routerLink="/opportunities">
                  <i class="fab fa-plus"></i>Cancel
                </a>
          </div>
      </div>
  </form>
