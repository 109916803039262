import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { JobsService } from 'src/app/shared/services/jobs.service';
import {Location} from '@angular/common';
import { Job } from 'src/app/shared/models/job';


@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditJobComponent implements OnInit {

  job: Job;
  jobForm: FormGroup;

  constructor(private fb: FormBuilder,
    private location: Location,
    private router: Router,
    private route: ActivatedRoute,
    private jobService: JobsService) {

  }

  ngOnInit(): void {// const param = this.route.snapshot.queryParamMap.getAll;
    this.jobForm = this.fb.group({
      jobTitle: ['', Validators.required],
      uid: ['', Validators.required],
      active: ['', Validators.required],
      experience: [''],
      skills: new FormArray([]),
      resposibilities : new FormArray([])
    });
    const jobId = this.route.snapshot.paramMap.get('jobId');
      console.log("jobId " + jobId);
      if (jobId) {
        this.jobService.getJobetails(jobId)
        .subscribe( data => {
          // this.job = data;
          console.log(this.job);
          this.jobForm = this.fb.group({
            uid: [jobId, Validators.required],
            jobTitle: [data.payload.data()['jobTitle'], Validators.required],
            active: [data.payload.data()['active'], Validators.required],
            experience: [data.payload.data()['experience'], Validators.required],
            skills: this.fb.array(data.payload.data()['skills']),
            resposibilities: this.fb.array(data.payload.data()['resposibilities'])
          });
            // this.createForm();
            return true;
        });
    }

  }

  removeSkill(index: number) {
    this.skills.removeAt(index);
  }

  get skills(): FormArray {
    return this.jobForm.get('skills') as FormArray;
  }


  removeResponsibilities(index: number) {
    this.resposibilities.removeAt(index);
  }

  get resposibilities(): FormArray {
    return this.jobForm.get('resposibilities') as FormArray;
  }

  addSkills(){
    this.skills.push(new FormControl());
    console.log("addSkills");
  }

  addResposibilities(){
    this.resposibilities.push(new FormControl());
    console.log("addResposibilities");
  }

  save(){
    console.log("save");
    this.jobService.updateJob(this.jobForm.get("uid").value, this.jobForm.value);
    this.router.navigate(['/opportunities']);
  }

  createForm() {
    console.log("this.item", this.job);
    this.jobForm = this.fb.group({
      jobTitle: [this.job.jobTitle, Validators.required],
      uid: [this.job.uid, Validators.required],
      active: [this.job.active, Validators.required]
    });
  }

}
