import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { JobsService } from 'src/app/shared/services/jobs.service';
import { AngularFirestore, AngularFirestoreCollection, DocumentChangeAction } from '@angular/fire/firestore';
import { Job } from 'src/app/shared/models/job';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss']
})
export class JobsComponent implements OnInit {

  job : any;
  constructor(public authService: AuthService,
    private jobService: JobsService,
    private metaTagService: Meta  ) { }

    ngOnInit() {
      this.metaTagService.updateTag(
        { name: 'keywords', content: 'Cero Technologies, Pune, Baner, Job opening, opportunity, apply now, available opening, developer, QA'}
      );

      this.jobService.readAllJobs().subscribe( data => {
          this.job = data;
          this.job = data.filter(e => {
            if(e.payload.doc.data()['active'] || this.authService.isCeroUser) {
              return true;
            }
            return false;
          }).map(e => {
            return {
              id: e.payload.doc.id,
              active: e.payload.doc.data()['active'],
              jobTitle: e.payload.doc.data()['jobTitle'],
              experience: e.payload.doc.data()['experience'],
              resposibilities:  e.payload.doc.data()['resposibilities'],
              skills:  e.payload.doc.data()['skills']
            };
        });
    });
  }

  addJob(){
    let job = {};
    job['jobTitle'] = "Software Developer";
    job['experience'] = "3+ years";
    job['skills'] = ["Skill 1", "Skill 2", "Skill 3"];
    job['resposibilities'] =  [ "Responsibility 1", "Responsibility 2", "Responsibility 3"];
    this.jobService.createJob(job).then(resp => {
      console.log(resp);
    });
  }


  deleteJob(jobId: string){
    console.log("Matching job found", jobId);
    this.jobService.readAllJobs().subscribe( data => {
      this.job = data.filter(e => {
        if(e.payload.doc.data()['active'] &&
        e.payload.doc.id == jobId) {
          console.log("Matching job found", e.payload.doc.id);
          this.job = {
              id: e.payload.doc.id,
              active: e.payload.doc.data()['active'],
              jobTitle: e.payload.doc.data()['jobTitle'],
              experience: e.payload.doc.data()['experience'],
              resposibilities:  e.payload.doc.data()['resposibilities'],
              skills:  e.payload.doc.data()['skills']
          };
          this.job['active'] = false;
          this.jobService.updateJob(jobId, this.job)
          return true;
        }
        // console.log("Matching job not found ", e.payload.doc.id);
        return false;
      });
    });
  }
}
