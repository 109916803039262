import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder,Validators, AbstractControl, ValidatorFn, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { Job } from 'src/app/shared/models/job';
import { JobsService } from 'src/app/shared/services/jobs.service';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddJobComponent implements OnInit {

  jobForm: FormGroup;
  job: Job;

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private jobService: JobsService) { }

  ngOnInit(): void {
    console.log("ngInit added")
    this.jobForm = this.formBuilder.group({
      jobTitle: ['', [Validators.required, Validators.minLength(3)]],
      experience: ['', [Validators.required, Validators.minLength(3)]],
      active: new FormControl(true),
      skills: this.formBuilder.array([]),
      resposibilities: this.formBuilder.array([])
    });

    // for (let i = 0; i < 3; ++i) {
    //   this.addSkills();
    // }
    // this.jobForm = new FormGroup({
    //   jobTitle: new FormControl(),
    //   experience: new FormControl(),
    //   active: new FormControl(true),
    //   responsibilities: FormControl[0]
    // });
  }

  // get products() { return this.jobForm.get('products'); }


  // addSkills(){
  //   // this.jobForm.setControl('addresses', this.formBuilder.array([addressGroup]));
  //   // this.products.push('');
  //   this.skillsArray .push(new FormControl('', [Validators.required, Validators.minLength(3)]));
  //   // this.jobForm.get("skills")(this.formBuilder.group({
  //   //   new FormControl()
  //   // }));
  //   console.log("called addSkills method");
  // }

  // get skillsArray() : FormArray{
  //   return this.jobForm.get('skills') as FormArray;
  // }

  // removeSkills(index){
  //   this.skillsArray.removeAt(index);
  // }

  // addResponsibilities(){

  // }

  // removeResponsibilities(index){

  // }

  // save(){
  //   // console.log(JSON.parse(this.jobForm.value) + " ");
  //   // console.log(JSON.stringify(this.jobForm.value) + " ");
  //   let job = {};
  //   job['jobTitle'] = this.jobForm.get('jobTitle').value;
  //   job['experience'] = this.jobForm.get('experience').value;
  //   job['active'] = this.jobForm.get('active').value;
  //   job['skills'] = ["Skill 1", "Skill 2", "Skill 3"];
  //   job['resposibilities'] =  [ "Responsibility 1", "Responsibility 2", "Responsibility 3"];
  //   console.log("before saving ", job)
  //   // this.jobService.createJob(job).then(resp => {
  //     // console.log(resp);
  //   // });
  //   console.log("saving ", job)
  // }


  removeSkill(index: number) {
    this.skills.removeAt(index);
  }

  get skills(): FormArray {
    return this.jobForm.get('skills') as FormArray;
  }


  removeResponsibilities(index: number) {
    this.resposibilities.removeAt(index);
  }

  get resposibilities(): FormArray {
    return this.jobForm.get('resposibilities') as FormArray;
  }

  addSkills(){
    this.skills.push(new FormControl());
    console.log("addSkills");
  }

  addResposibilities(){
    this.resposibilities.push(new FormControl());
    console.log("addResposibilities");
  }

  save(){
    console.log("save");
    this.jobService.save(this.jobForm.value);
    this.router.navigate(['/opportunities']);
  }


}
