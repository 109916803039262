import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentChangeAction, DocumentSnapshot } from '@angular/fire/firestore';
import { Job } from '../models/job';
import { Observable, of } from 'rxjs';
import { Action } from 'rxjs/internal/scheduler/Action';

@Injectable({
  providedIn: 'root'
})
export class JobsService {

  constructor(
    private firestore: AngularFirestore
  ) { }

  JOBS_COLLECTION : string = "jobs3";
  createJob(job) {
    return this.firestore.collection(this.JOBS_COLLECTION).add(job);
  }

  readAllJobs() : Observable<DocumentChangeAction<any>[]> {
    return this.firestore.collection(this.JOBS_COLLECTION).snapshotChanges();
  }


  getJobetails(jobId : string){
    return this.firestore.collection(this.JOBS_COLLECTION).doc(jobId).snapshotChanges();
  }

  updateJob(recordID,job){
    console.log("for record id ", recordID);
    console.log("for job ", job);
    return this.firestore.collection(this.JOBS_COLLECTION).doc(recordID).set(job);
  }

  save(job){
    console.log("for job ", job);
    return this.firestore.collection(this.JOBS_COLLECTION).add(job);
  }
}
