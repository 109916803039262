import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { JobsComponent } from './jobs.component';
import { AuthGuard } from 'src/app/common/gaurd/auth.guard';
import { AddJobComponent } from './add/add.component';
import { EditJobComponent } from './edit/edit.component';


const routes: Routes = [{
    path: '',
    component: JobsComponent,
  },{
    path : 'add',
    component: AddJobComponent,
    canActivate : [AuthGuard]
  },{
    path : 'edit/:jobId',
    component: EditJobComponent,
    canActivate : [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class JobsRoutingModule { }
