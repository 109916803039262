import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './feature/home/home.component';
import { TeamComponent } from './feature/team/team.component';
import { JobsComponent } from './feature/jobs/jobs.component';
import { NotfoundComponent } from './common/notfound/notfound.component';
import { LoginComponent } from './feature/login/login.component';
import { DashboardComponent } from './feature/dashboard/dashboard.component';


const routes: Routes = [{
  path:'', component: HomeComponent
  },{
    path:'home', component: HomeComponent
  },{
    path:'team',
    loadChildren: './feature/team/team.module#TeamModule'
  },{
    path:'opportunities',
    loadChildren: './feature/jobs/jobs.module#JobsModule'
  },{
    path:'login', component: LoginComponent
  },{
    path:'dashboard', component: DashboardComponent
  },{
    path: '**',
    component: NotfoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
