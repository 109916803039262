<div class="container">
    <!-- Resources -->
      <div class="cover-content wrapper text-center">
        <a class="logo" routerLink="/home">
          <img src="assets/img/base/cero.png"/>
        </a>
        <h1>
          <span class="fadeIn">
            <b>  People.</b> Product. Technology.
          
          </span>
          <span class="reveal"></span>
        </h1>
      </div>
      <div class="wrapper">
        <div class="row">
          <div class="col-sm-12 text-center">
            <!-- <img src="assets/img/base/labcase.png"> -->
            <h1>A compact team of technology people building next generation product.</h1>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-sm-4">
            <h4>Technology.</h4>
            <p>We're working on a product which uses cutting edge technologies.</p>
          </div>
          <div class="col-sm-4">
            <h4>Talent.</h4>
            <p>We're always looking for a talent who loves solving problems.</p>
          </div>
          <div class="col-sm-4">
            <h4>Team.</h4>
            <p>We're in process of building a team of technology expert and problem solver.</p>
          </div>
        </div>
      </div>
  </div>